const headers = [
    {
        text: 'Todos',
        align: 'left small-th-roles-form ',
        value: 'all',
        sortable: false,
        class: 'table-b-border  black--text small-th-roles-form'
    },
    {
        text: 'Crear',
        align: 'left small-th-roles-form',
        value: 'create',
        sortable: false,
        class: 'table-b-border  black--text small-th-roles-form'
    },
    {
        text: 'Ver',
        align: 'left small-th-roles-form',
        value: 'show',
        sortable: false,
        class: 'table-b-border  black--text small-th-roles-form'
    },
    {
        text: 'Editar',
        align: 'left small-th-roles-form',
        value: 'update',
        sortable: false,
        class: 'table-b-border  black--text small-th-roles-form'
    },
    {
        text: 'Borrar',
        align: 'left small-th-roles-form',
        value: 'delete',
        sortable: false,
        class: 'table-b-border  black--text small-th-roles-form'
    },
    
];

const headersSells = [
    {
        text: 'Todos',
        align: 'left small-th-roles-form',
        value: 'all',
        sortable: false,
        class: 'table-b-border  black--text small-th-roles-form'
    },
    {
        text: 'Crear',
        align: 'left small-th-roles-form',
        value: 'create',
        sortable: false,
        class: 'table-b-border  black--text small-th-roles-form'
    },
    {
        text: 'Ver',
        align: 'left small-th-roles-form',
        value: 'show',
        sortable: false,
        class: 'table-b-border  black--text small-th-roles-form'
    },
    {
        text: 'Editar',
        align: 'left small-th-roles-form',
        value: 'update',
        sortable: false,
        class: 'table-b-border  black--text small-th-roles-form'
    },
    {
        text: 'Borrar',
        align: 'left small-th-roles-form',
        value: 'delete',
        sortable: false,
        class: 'table-b-border  black--text small-th-roles-form'
    },
    {
        text: 'Duplicar',
        align: 'left small-th-roles-form',
        value: 'copy',
        sortable: false,
        class: 'table-b-border  black--text small-th-roles-form'
    },
    {
        text: 'Aprobar',
        align: 'left small-th-roles-form',
        value: 'review',
        sortable: false,
        class: 'table-b-border  black--text small-th-roles-form'
    },
    {
        text: 'Convertir\na oc',
        align: 'left small-th-roles-form',
        value: 'convert',
        sortable: false,
        class: 'table-b-border  black--text small-th-roles-form white-space-normal'
    },
    {
        text: 'Ver todas',
        align: 'left small-th-roles-form',
        value: 'showAll',
        sortable: false,
        class: 'table-b-border  black--text small-th-roles-form'
    },
    
]

const modules = [
  // {label:"Root", submodules:[ {key:"root", label: "Administrador"} ]},
    {
        props: {
            headers: [
                {
                    text: 'Obras',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers.filter((h) => h.text != "Crear")
            ],
            dense:true
        },
        label: "Obras",
        submodules: [
            
            { key: "buildings", label: "Obras", notIn: ["create", "cancel", "copy", "review", "convert", "showAll"]  },
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Compras',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
            dense:true
           
        },
        label: "Compras",
        submodules: [
           
            { key: "suppliers", label: "Proveedores", notIn: ["copy", "review", "convert", "showAll"]  },
            { key: "concepts", label: "Catálogo", notIn: ["copy", "review", "convert", "showAll"]  },
            // { key: "products", label: "Materias primas", notIn: []  },
            
            // { key: "requisitions", label: "Requisiciones", notIn: []  },
            { key: "purchaseOrders", label: "Órdenes de compra", notIn: ["copy", "review", "convert", "showAll"]  },
            { key: "archive", label: "Archivo ODC", notIn: ["copy", "review", "convert", "showAll", "create", "update", "delete"]  },
           
            
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Compras/Requisiciones',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headersSells
            ],
            dense:true
           
        },
        label: "Compras",
        submodules: [
           
            // { key: "suppliers", label: "Proveedores", notIn: ["copy", "review", "convert", "showAll"]  },
            // { key: "concepts", label: "Catálogo", notIn: ["copy", "review", "convert", "showAll"]  },
            // { key: "products", label: "Materias primas", notIn: []  },
            
            { key: "requisitions", label: "Requisiciones", notIn: []  },
            // { key: "purchaseOrders", label: "Órdenes de compra", notIn: ["copy", "review", "convert", "showAll"]  },
           
            
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Ventas',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
            dense:true
        },
        label: "Ventas",
        submodules: [
           
            { key: "clients", label: "Clientes", notIn: ["copy", "review", "convert", "showAll"]  },
            { key: "bigProducts", label: "Productos", notIn: ["copy", "review", "convert", "showAll"]  },
            // { key: "sellers", label: "Vendedores", notIn: []  },
            { key: "quotes", label: "Cotizaciones", notIn: ["copy", "review", "convert", "showAll"]  },
            { key: "saleOrders", label: "Ordenes de trabajo", notIn: ["copy", "review", "convert", "showAll"]  },
           
            
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Contabilidad',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
            dense:true
        },
        label: "Contabilidad",
        submodules: [
            { key: "banks", label: "Bancos", notIn: ["copy", "review", "convert", "showAll"]  },
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Accesos',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
            dense:true
        },
        label: "Accesos",
        submodules: [
           
            { key: "users", label: "Usuarios", notIn: ["copy", "review", "convert", "showAll"]  },
            { key: "roles", label: "roles", notIn: ["copy", "review", "convert", "showAll"]  },
            
        ],
    },
    {
        props: {
            headers: [
                {
                    text: 'Catálogos',
                    align: 'left',
                    value: 'label',
                    sortable: false,
                    width:'40%',
                    class: 'table-b-border  black--text'
                },
                ...headers
            ],
            dense:true
        },
        label: "Catálogos",
        submodules: [
            { key: "catalog_bank", label: "Bancos", notIn: ["copy", "review", "convert", "showAll"]  },
            { key: "catalog_unit", label: "Unidades",notIn: ["copy", "review", "convert", "showAll"] }
            
        ],
    },
    
];

export default modules;
